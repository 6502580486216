import Header from "./components/Header";
import Main from "./components/Main";
import React, {useEffect, useState} from "react";
import Player from "./components/Player";
import NowIsPlaying from "./components/NowIsPlaying";
function App() {

    const [googletag,setGoogletag] = useState(false);
    const [googletagIsSet,setgGoogletagIsSet] = useState(false);

    function initGoogleTag()
    {
        let g = window.googletag || {};
        setGoogletag(g);

        if(googletag != false) {
            googletag.cmd = googletag.cmd || [];
            // (function () {
            //     var gads = document.createElement('script');
            //     gads.async = true;
            //     gads.type = 'text/javascript';
            //     var useSSL = 'https:' == document.location.protocol;
            //     gads.src = (useSSL ? 'https:' : 'http:') +
            //         '//www.googletagservices.com/tag/js/gpt.js';
            //     var node = document.getElementsByTagName('script')[0];
            //     node.parentNode.insertBefore(gads, node);
            // })();

            var pubs = [{"ID":364,"field":{"type":"Google ads","image":null,"gid":"leaderboard_adsens_02_NS","code":"\/118616137\/leaderboard_adsens_02_NS","size":[{"pm_pubs_longeur":"728","pm_pubs_hauteur":"90"}],"fate_start":"2024-01-01 00:00:00","date_end":"2024-06-19 00:00:00"}},
                {"ID":362,"field":{"type":"Google ads","image":null,"gid":"leaderboard_adsens_01_NS","code":"\/118616137\/leaderboard_adsens_01_NS","size":[{"pm_pubs_longeur":"728","pm_pubs_hauteur":"90"}],"fate_start":"2024-01-01 00:00:00","date_end":"2024-07-27 00:00:00"}},
                {"ID":361,"field":{"type":"Google ads","image":null,"gid":"leaderboard_top_NS","code":"\/118616137\/leaderboard_top_NS","size":[{"pm_pubs_longeur":"970","pm_pubs_hauteur":"250"}],"fate_start":"2024-01-01 00:00:00","date_end":"2024-05-09 00:00:00"}},
                {"ID":360,"field":{"type":"Google ads","image":null,"gid":"bigbox_grand_NS","code":"\/118616137\/bigbox_grand_NS","size":[{"pm_pubs_longeur":"300","pm_pubs_hauteur":"600"}],"fate_start":"2024-01-03 00:00:00","date_end":"2024-07-25 00:00:00"}},
                {"ID":358,"field":{"type":"Google ads","image":null,"gid":"bigbox_promo_NS","code":"\/118616137\/bigbox_promo_NS","size":[{"pm_pubs_longeur":"480","pm_pubs_hauteur":"500"}],"fate_start":"2024-01-01 00:00:00","date_end":"2024-05-16 00:00:00"}},
                {"ID":352,"field":{"type":"Google ads","image":null,"gid":"bigbox_petit_NS","code":"\/118616137\/bigbox_petit_NS","size":[{"pm_pubs_longeur":"300","pm_pubs_hauteur":"250"}],"fate_start":"2024-01-02 00:00:00","date_end":"2024-07-26 00:00:00"}}];

            googletag.cmd.push(function() {

                pubs.forEach(function(item) {
                    let sizes = item.field.size;
                    let size = [];
                    sizes.forEach(function(s) {
                        size.push([parseInt(s.pm_pubs_longeur),parseInt(s.pm_pubs_hauteur)]);
                    });

                    // console.log(item.field.code + ' | ' + size + ' | ' + item.field.gid);

                    googletag.defineSlot(item.field.code, size, item.field.gid).addService(googletag.pubads());
                });

                googletag.enableServices();
                setgGoogletagIsSet(true);
            });


        }


    }



    useEffect(() => {

        initGoogleTag();


    }, [googletag]);

  return (
      <div>
        <div id="main_container">
            {/*<NowIsPlaying/>*/}
            <div id="td_container"></div>
            <Header googletag={googletag} googletagIsSet={googletagIsSet}/>
            <Main googletag={googletag} googletagIsSet={googletagIsSet} />
        </div>
          {/*<div id="footer_spacer"></div>*/}
        <Player/>
      </div>
  );
}

export default App;
