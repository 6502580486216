import Pub_header from "./Pub_header";

function Header(props)
{
    return <header>
                <div id="logo"><img src="images/logo-M105-radio-granby.svg"/></div>
                <Pub_header googletag={props.googletag} googletagIsSet={props.googletagIsSet} />
            </header>
}

export default Header