import parse from "html-react-parser";
import React from "react";

function CarouselItem (props)
{
    if(props.slide != '') {
        {
            return <li>
                {props.slide.lien != "" &&
                <a href={props.slide.lien} target="_blank"></a>
                }
                <div className="carousel_image" style={{backgroundImage: `url(${props.slide.image.url})`}}></div>

            </li>;
        }
    }
}


export default CarouselItem