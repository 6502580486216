function CarouselBar (props)
{

    const index = props.index;
    function change_slide(index)
    {
        props.setcarouselCurrent(index);
    }
    return <li className={(props.current == true ? "current" : "")} onClick={change_slide.bind(this, props.index)}></li>
}

export default CarouselBar