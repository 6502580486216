import React, { useState,useEffect} from "react";
import Volume from "./Volume";
import axios from "axios";
import {useSelector,useDispatch} from "react-redux";
import { useCookies } from 'react-cookie'

function Player(props)
{

    const [playerIsPlayed,setPlayerIsPlayed] = useState(false);
    const [playerArtiste,setPlayerArtiste] = useState('');
    const [playerSong,setPlayerSong] = useState('');
    // const [player,setPlayer] = useState(false);
    const [programmation,setProgrammation] = useState('');
    const [programmationAnimateurs,setProgrammationAnimateurs] = useState('');
    const [programmationDateFin,setProgrammationDateFin] = useState('');
    const [programmationTitle,setProgrammationTitle] = useState('');
    const [programmationImage,setProgrammationImage] = useState('');
    const [listeners,setlisteners] = useState({});
    const list = useSelector(state => state.NowIsPlayingList);
    const player = useSelector(state => state.player);
    const volume = useSelector(state => state.volume);
    // const [volume,setVolume] = useState(0.5);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['video_timestamp']);


    let tempPlayer = '';



    function loadProgrammationRequest()
    {

        const params = new URLSearchParams();
        const url = 'https://m105.ca/wp-admin/admin-ajax.php';
        params.append('action', 'get_current_programmation');


        const handleSubmit = async () => {
            try {
                const response = await axios.post(url, params);
                setProgrammation(response.data);

                setProgrammationImage(response.data.image);
                setProgrammationTitle(response.data.data.post_title);
                setProgrammationDateFin(response.data.date.fin.substring(0,5).replace(':',' h '));

                // let animateurs = [];
                let animateurs = "";
                if(response.data.animateurs !== null) {
                    // response.data.animateurs.forEach((val, index) => {
                    //     animateurs.push(val.post_title);
                    // });

                    animateurs = response.data.animateurs

                }
                setProgrammationAnimateurs(animateurs);
                // setProgrammationAnimateurs(animateurs.join(', '));


            } catch (error) {
                console.log(error);
            }
        };

        handleSubmit();
    }

    function ifPlayVideoPub()
    {
        let now = Date.now();
        if(cookies['video_timestamp'] != 1)
        {
            return true;
        }

        return false;
    }

    function playVideo()
    {
        if(player !== false) {
            player.playAd('tap', {
                host: 'cmod.live.streamtheworld.com',
                type: 'preroll',
                format: 'vast',
                stationId: 41493
            });
        }
    }

    function play()
    {
        if(playerIsPlayed === false) {
            onPlayerFullReady();
            player.setVolume(volume);

            setPlayerIsPlayed(true);

            if(ifPlayVideoPub())
            {
                playVideo();
            }
            else
            {
                playStation();
            }

        }

        if(playerIsPlayed === true)
        {
            stop();
        }

    }

    function loadNowIsPlaying()
    {
        if(player !== false) {
            /* load la liste des joué récement */
            player.NowPlayingApi.load({mount: 'CFXMFM', hd: true, numberToFetch: 20});
        }
    }

    function playStation()
    {
        hide_video();
        player.play({station: 'CFXMFM'});
        loadNowIsPlaying();
    }

    function stop()
    {
        setPlayerIsPlayed(false);
        hide_video();
        player.stop();

        setPlayerArtiste('');
        setPlayerSong('');

    }



    /* Callback function called to notify that the SDK is ready to be used */
    function onPlayerReady()
    {
        dispatch({type: 'setPlayer', value: tempPlayer});
    }

    function onPlayerFullReady()
    {

        if(player !== false) {

            /* remove les listeners */
            for (const [key, value] of Object.entries(listeners)) {
                let t = player.listeners.find(({ eventName }) => eventName === key);
                if(t !== undefined)
                {
                    player.removeEventListener(t.eventName, t.callback);
                }
            }

            /* ajoute les listeners */
            for (const [key, value] of Object.entries(listeners)) {
                player.addEventListener(key, eval(value));
            }

        }

    }

    function listEmpty(e)
    {
        console.log('List empty '+ e);
    }

    function nowplayingApiError(e)
    {
        console.log('List error '+ e);
    }

    function listLoaded(e)
    {
        dispatch({type: 'setList',value: e.data.list});
    }

    function streamStop(e)
    {
        // console.log('stop call');
    }

    function adPlaybackStart(e)
    {
        // console.log('Ad video start');
        player.setVolume(volume);
    }

    function adPlaybackComplete(e)
    {
        let now = Date.now();
        let expires = new Date();
        expires.setTime(expires.getTime() + (600 * 1000));

        setCookie('video_timestamp', 1, { path: '/',  expires});
        // console.log('Ad video end');
        playStation();
    }

    function adPlaybacError(e)
    {
        console.log(e);
        playStation();
    }

    function adBreakSyncedElement(e)
    {
        // console.log('adBreakSyncedElement');
    }

    function vpaidAdCompanions(e)
    {
        // console.log('vpaidAdCompanions');
    }
    function adBreakCuePointComplete(e)
    {
        // console.log('Ad done');
        hide_video();
    }

    function adBreakCuePoint(e)
    {
        // console.log('Ad start');
        setPlayerArtiste('Publicité');
        setPlayerSong('');
    }


    function onTrackCuePointAd( e )
    {
        console.log(e)
        setPlayerArtiste('Publicité');
        setPlayerSong('');

    }
    function onTrackCuePointCustom( e )
    {
        console.log(e)
        setPlayerArtiste(e.data.cuePoint.cueTitle);
        setPlayerSong('');

    }
    function onTrackCueSpeech( e )
    {
        console.log(e)
        setPlayerArtiste(e.data.cuePoint.artistName);
        setPlayerSong(e.data.cuePoint.cueTitle);
    }

    /* Callback function called to notify that the player configuration has an error. */
    function onConfigurationError( e ) {
        console.log(e);
        console.log(e.data.errors);
        //Error code : object.data.errors[0].code
        //Error message : object.data.errors[0].message
    }

    /* Callback function called to notify that a module has not been loaded properly */
    function onModuleError( object )
    {
        console.log(object);
        console.log(object.data.errors);
        //Error code : object.data.errors[0].code
        //Error message : object.data.errors[0].message
    }

    /* Callback function called to notify that a new Track CuePoint comes in. */
    function onTrackCuePoint( e )
    {
        setPlayerArtiste(e.data.cuePoint.artistName);
        setPlayerSong(e.data.cuePoint.cueTitle);
        loadNowIsPlaying();
        // console.log(player.getVolume());
    }

    /* Callback function called to notify that an Ad-Blocker was detected */
    function onAdBlockerDetected()
    {
        // console.log( 'AdBlockerDetected' );
    }

    function hide_video()
    {
        if(document.getElementById('tdplayer_ondemand')) {
            document.getElementById('tdplayer_ondemand').style.display = "none";
        }
    }


    function initPlayerConfig()
    {

        let c = {
            coreModules: [{
                id: 'MediaPlayer',
                playerId: 'td_container'
            }, { id: 'NowPlayingApi' }],
            playerReady: onPlayerReady,
            configurationError: onConfigurationError,
            moduleError: onModuleError,
            adBlockerDetected: onAdBlockerDetected,
        };

        tempPlayer = new window.TDSdk( c );

    }


    useEffect(() => {

        if(cookies['volume'] != undefined)
        {
            let vol = cookies['volume']/100;
            dispatch({type: 'setVolume', value: vol});
        }
        else
        {
            setCookie('volume', (volume*100), { path: '/'})
        }

        /* init la liste des listeners */
        setlisteners({
            'track-cue-point': 'onTrackCuePoint',
            'speech-cue-point': 'onTrackCueSpeech',
            'custom-cue-point': 'onTrackCuePointCustom',
            'ad-break-cue-point': 'onTrackCuePointAd',
            'ad-break-cue-point': 'adBreakCuePoint',
            'ad-break-cue-point-complete': 'adBreakCuePointComplete',
            'ad-break-synced-element': 'adBreakSyncedElement',
            'vpaid-ad-companions': 'vpaidAdCompanions',
            'ad-playback-start': 'adPlaybackStart',
            'ad-playback-complete': 'adPlaybackComplete',
            'ad-playback-error': 'adPlaybacError',
            'stream-stop': 'streamStop',
            'list-loaded': 'listLoaded',
            'list-empty': 'listEmpty',
            'nowplaying-api-error': 'nowplayingApiError'
        });

        loadProgrammationRequest();
        initPlayerConfig();

    }, []);










    return <div id="player_block">
        <div id="player_play_block" onClick={play} isplaying={playerIsPlayed.toString()}>
            {!playerIsPlayed &&
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="28" width="23" viewBox="0 0 448 512">
                    <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/>
                </svg>
            </div>
            }
            {playerIsPlayed &&
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="28" width="23" viewBox="0 0 448 512">
                    <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"/>
                </svg>
            </div>
            }
        </div>
        <div id="player_programmation_block">
            {programmation != '' &&
            <div id="player_programmation_container">
                {programmationImage != '' && programmationImage != null &&
                <div id="player_programmation_container_img"><img src={programmationImage}/></div>
                }
                <div id="player_programmation_container_info">
                    {programmationDateFin != '' &&
                    <div id="player_programmation_container_info_en_onde">En ondes jusqu'à {programmationDateFin}</div>
                    }
                    {programmationTitle != '' &&
                    <div id="player_programmation_container_info_title">{programmationTitle}</div>
                    }
                    {programmationAnimateurs != '' &&
                    <div id="player_programmation_container_info_animateur">{programmationAnimateurs}</div>
                    }
                </div>
            </div>
            }
        </div>
        <div id="player_container_block_info">
            {playerIsPlayed &&
                <div>
                    <div id="player_container_block_info_title">En cours de lecture</div>
                    <div id="player_container_block_info_artiste" title={playerArtiste}>{playerArtiste}</div>
                    <div id="player_container_block_info_song">{playerSong}</div>
                </div>
            }
        </div>
        <div id="player_container_block_volume">
            <Volume player={player} />
        </div>
        {/*<div id="player_equalizer">*/}
        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="90px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">*/}
        {/*        <g transform="rotate(180 50 50)"><rect x="6.111111111111111" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.625s"></animate>*/}
        {/*        </rect><rect x="17.22222222222222" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.75s"></animate>*/}
        {/*        </rect><rect x="28.333333333333336" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.375s"></animate>*/}
        {/*        </rect><rect x="39.44444444444444" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.875s"></animate>*/}
        {/*        </rect><rect x="50.55555555555556" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.125s"></animate>*/}
        {/*        </rect><rect x="61.66666666666667" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="0s"></animate>*/}
        {/*        </rect><rect x="72.77777777777777" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.5s"></animate>*/}
        {/*        </rect><rect x="83.88888888888889" y="12.5" width="10" height="40" fill="#ffffff">*/}
        {/*            <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.25s"></animate>*/}
        {/*        </rect></g>*/}
        {/*    </svg>*/}
        {/*</div>*/}
        <script src="//sdk.listenlive.co/web/2.9/td-sdk.min.js"></script>
    </div>
}


export default Player;