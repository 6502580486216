import Pub_right from "./Pub_right";
import Carousel from "./Carousel";
import React, {useEffect, useState} from "react";

function Main(props)
{
    const [mainHeight,setMainHeight] = useState(false);
    function resize()
    {
        let windowHeight = window.innerHeight;
        let headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
        let footerHeight = document.getElementById('player_block').offsetHeight;
        setMainHeight(windowHeight - headerHeight - footerHeight);

        console.log(windowHeight + ' - ' + headerHeight + ' - ' + footerHeight + ' = ' + (windowHeight - headerHeight - footerHeight));
    }

    useEffect(() => {
        resize();
        window.addEventListener('resize', resize);


    }, []);

    return <main style={{height: mainHeight}}>
                <Carousel/>
                <Pub_right googletag={props.googletag} googletagIsSet={props.googletagIsSet}/>
            </main>
}

export default Main