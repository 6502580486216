import {useEffect} from "react";

function Pub_header(props)
{
    let pubId = 'leaderboard_adsens_01_NS';


    function addPub()
    {
        if(props.googletagIsSet && props.googletag != false && Object.keys(props.googletag).length > 0) {
            props.googletag.cmd.push(function() {
                props.googletag.display(pubId);
            });
        }
    }

    useEffect(() => {

        addPub();


    }, [props.googletagIsSet]);

    return <div id="pub_header">
        <div id={pubId} className="pub_banner"></div>
    </div>
}

export default Pub_header;