import {createStore} from 'redux'

const counterReducer = (state = {NowIsPlayingList: '',player: false, volume: 0.5}, action) => {


    if(action.type === 'setList')
    {
        return {
            NowIsPlayingList: action.value,
            player: state.player,
            volume: state.volume
        };
    }

    if(action.type === 'setPlayer')
    {
        return {
            player: action.value,
            NowIsPlayingList: state.NowIsPlayingList,
            volume: state.volume
        };
    }

    if(action.type === 'setVolume')
    {
        return {
            volume: action.value,
            player: state.player,
            NowIsPlayingList: state.NowIsPlayingList

        };
    }

    return state;
}
const store = createStore(counterReducer);

export default store;