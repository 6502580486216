import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";

function Volume(props)
{

    const [volumeOpen,setVolumeOpen] = useState(false);
    const [cookies, setCookie] = useCookies(['volume']);
    const volume = useSelector(state => state.volume);
    const player = useSelector(state => state.player);
    const dispatch = useDispatch();



    function openVolume()
    {
        if(volumeOpen === true)
        {
            setVolumeOpen(false);
        }

        if(volumeOpen === false)
        {
            setVolumeOpen(true);
        }
    }

    window.jQuery( "#volume" ).slider({
        orientation: "vertical",
        range: "min",
        min: 1,
        max: 100,
        value: volume*100,
        classes: {
            "ui-slider": "highlight"
        },
        slide: function( event, ui ) {
            let vol = ui.value / 100;
            dispatch({type: 'setVolume', value: vol});
            setCookie('volume', ui.value, { path: '/'});

        }
    });

    useEffect(() => {
        if(player !== false) {
            player.setVolume(volume);
        }

    }, [volume]);




    return <div id="volume_block">
        <div id="volume_icon" onClick={openVolume}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="23" viewBox="0 0 480 512">
                <path d="M394.2 100.9c-11.2-7.1-26-3.8-33.1 7.4s-3.8 26 7.4 33.1C408.3 166.6 432 209.4 432 256s-23.7 89.4-63.5 114.6c-11.2 7.1-14.5 21.9-7.4 33.1 6.5 10.3 21.1 15 33.1 7.4C447.9 377.1 480 319.1 480 256s-32.1-121.1-85.8-155.2zm-56 78.3c-11.6-6.3-26.2-2.2-32.6 9.5-6.4 11.6-2.2 26.2 9.5 32.6C328 228.3 336 241.6 336 256c0 14.4-8 27.7-20.9 34.8-11.6 6.4-15.8 21-9.5 32.6 6.4 11.7 21.1 15.8 32.6 9.5 28.2-15.6 45.8-45 45.8-76.9s-17.5-61.3-45.8-76.9zM231.8 64c-5.9 0-11.9 2.2-16.8 7.1L126.1 160H24c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h102.1l89 89c4.9 4.9 10.9 7.1 16.8 7.1 12.3 0 24.2-9.5 24.2-24V88C256 73.5 244.1 64 231.8 64zM208 366.1L145.9 304H48v-96h97.9L208 146v220.1z"/>
            </svg>
        </div>
        <div id="volume_slider" className={(volumeOpen) ? 'open' : ''}>
            <div id="volume"></div>
        </div>
    </div>
}

export default Volume;