import axios from "axios";
import React, { useState,useEffect} from "react";
import parse from "html-react-parser";
import CarouselItem from "./CarouselItem";
import CarouselBar from "./CarouselBar";

function Carousel (props)
{

    const [carousel,setCarousel] = useState('');
    const [carouselCurrent,setcarouselCurrent] = useState(-1);
    const [carouselOpacity,setCarouselOpacity] = useState(1);
    const [carouselTimer,setCarouselTimer] = useState('');

    function loadCarousel()
    {
        const params = new URLSearchParams();
        const url = 'https://m105.ca/wp-admin/admin-ajax.php';
        params.append('action', 'playerCarousel');



        const handleSubmit = async () => {
            try {
                const response = await axios.post(url, params);
                setcarouselCurrent(0);
                setCarousel(response.data);


            } catch (error) {
                console.log(error);
            }
        };

        handleSubmit();
    }

    function nextCarousel(index = -1) {

        if (index == -1)
        {
            setcarouselCurrent((carouselCurrent + 1));
        }
        else
        {
            setcarouselCurrent(index);
        }
    }



    useEffect(() => {
        loadCarousel();

    }, []);

    useEffect(() => {
        if(carousel != '') {
            let nb = carousel.slide.length -1 ;
            setCarouselOpacity(0.4);
            setTimeout(function () {
                setCarouselOpacity(1);
            }, 300);
            setCarouselTimer(clearTimeout(carouselTimer));
            let next = parseInt(carouselCurrent + 1);
            if(next > nb)
            {
                next = 0;
            }

            setCarouselTimer(setTimeout(function() { nextCarousel(next) }, 5000));
        }
    },[carouselCurrent])




    return <div id="carousel">
        {carousel != '' &&
       <ul style={{left: carouselCurrent*-100+'%',opacity: carouselOpacity}}>
           {carousel.slide.map((slide, index) => (
               <CarouselItem slide={slide} key={slide.id}/>
           ))}
       </ul>
        }
        {carousel == '' &&
            <div id="carousel_loading">
                <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(180 50 50)"><rect x="6.111111111111111" y="12.5" width="10" height="40" fill="#ee3e26">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.625s"></animate>
                    </rect><rect x="17.22222222222222" y="12.5" width="10" height="40" fill="#e53a26">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.75s"></animate>
                    </rect><rect x="28.333333333333336" y="12.5" width="10" height="40" fill="#d93625">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.375s"></animate>
                    </rect><rect x="39.44444444444444" y="12.5" width="10" height="40" fill="#cb3125">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.875s"></animate>
                    </rect><rect x="50.55555555555556" y="12.5" width="10" height="40" fill="#bc2c24">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.125s"></animate>
                    </rect><rect x="61.66666666666667" y="12.5" width="10" height="40" fill="#ad2623">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="0s"></animate>
                    </rect><rect x="72.77777777777777" y="12.5" width="10" height="40" fill="#a22125">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.5s"></animate>
                    </rect><rect x="83.88888888888889" y="12.5" width="10" height="40" fill="#9a1f24">
                        <animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.25s"></animate>
                    </rect></g>
                </svg>
                <div>En chargement</div>
            </div>
        }
        <div id="carousel_bar">
            <ul>
                {carousel != '' && carousel.slide.map((slide, index) => (
                    <CarouselBar current={carouselCurrent == index && true} setcarouselCurrent={setcarouselCurrent} index={index} key={index} />
                ))}
            </ul>
        </div>
    </div>

    }

export default Carousel;