import {useEffect} from "react";

function Pub_right(props)
{
    let pubId = 'bigbox_petit_NS';

    function addPub()
    {
        if(props.googletagIsSet && props.googletag != false && Object.keys(props.googletag).length > 0) {
            props.googletag.cmd.push(function() {
                props.googletag.display(pubId);
            });
        }
    }

    useEffect(() => {

        addPub();


    }, [props.googletagIsSet]);
    return <div id="pub_right_block">
                <div id={pubId}></div>
            </div>
}

export default Pub_right